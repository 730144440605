import request from "@/utils/request"

export default {
    goodsList(params = {}) {
        return request.get("/goods/list", {
            params
        });
    },
    goodsDetail(goodsId) {
        return request.get(`/goods/${goodsId}`);
    },
    serveList(params = {}) {
        return request.get("/serve/list", {
            params
        })
    },
    serveDetail(serveId) {
        return request.get(`/serve/${serveId}`);
    },
    hitsCurrentGoods(goodsId) {
        return request.post(`/goods/hitsCurrentGoods?goodsId=${goodsId}`)
    },
    hitsCurrentServe(serveId) {
        return request.post(`/serve/hitsCurrentServe?serveId=${serveId}`)
    },
    getGoodsImagesFiles(params = {}) {
        return request.post("/file/getGoodsImagesFiles", 
            params
        );
    },
}