<template>
  <div class="farm_service_index">
    <a-spin :spinning="loading">
      <a-row v-if="list.length > 0" :gutter="30">
        <a-col v-for="item in list" :key="item.goodsId" :span="8">
          <div class="item">
            <router-link
              :to="{
                name: 'farm_service_goods_detail',
                params: { id: item.goodsId },
              }"
            >
              <div class="pic">
                <div class="last">还剩{{ item.count }}件</div>
                <img :src="item.imageFiles.length>0?item.imageFiles[0].url:fileUrl.defaultUrl" alt="" />
              </div>
              <div class="info">
                <div class="price">
                  ¥ <b>{{ item.price }}</b>
                </div>
                <div class="title">{{ item.name }}</div>
                <p>{{ item.paramValues[0]?item.paramValues[0].paramValue:"未添加" }}</p>
              </div>
            </router-link>
          </div>
        </a-col>
      </a-row>
      <a-empty v-else />
      <custom-page></custom-page>
    </a-spin>
  <div></div>
  </div>
</template>

<script type="text/ecmascript-6">
import Api from "@/api/farm_service";
export default {
  data() {
    return {
      list: [],
      current: 1,
      loading: false,
      total: 0,
      fileUrl:{
        url:'',
        defaultUrl:require('@/assets/images/image3.png'),
      },
    };
  },
  components: {},
  props: {
    keyword: { type: String, default: () => null },
  },
  watch: {
    keyword() {
      this.getList();
    },
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      Api.goodsList({
        pageSize: 12,
        pageNum: this.current,
        organizationName: this.keyword,
      }).then((res) => {
        this.loading = false;
        this.list = res.result.records;
        this.total = res.result.total;
      });
    },
    changePage(page) {
      this.current = page;
      this.getList();
    },
  },
};
</script>

<style scoped lang="scss">
.farm_service_index {
  .toolbar {
    border-bottom: 2px solid #eee;
    height: 58px;
    overflow: hidden;
    .search {
      float: right;
      width: 30%;
    }
  }
  .list {
    margin-top: 25px;
    .item {
      margin-bottom: 15px;
      border: 1px solid #f0f0f0;
      &:hover {
        border-color: #008840;
      }
      a {
        text-decoration: none;
      }
      .pic {
        position: relative;
        img {
          width: 100%;
          height:200px;
        }
        .last {
          position: absolute;
          top: 0;
          left: 0;
          background: url("~@/assets/images/bg.png");
          padding: 0 15px;
          line-height: 30px;
          line-height: 30px;
          color: white;
        }
      }
      .info {
        position: relative;
        line-height: 2em;
        padding: 10px;
        background: #fafafa;
        .title {
          font-size: 18px;
          color: #333;
        }
        p {
          margin: 0;
          font-size: 16px;
          color: #777;
        }
        .price {
          position: absolute;
          background: white;
          border-radius: 50%;
          width: 70px;
          height: 70px;
          line-height: 70px;
          text-align: center;
          box-shadow: 0 0 10px #d1d1d1;
          right: 20px;
          top: -30px;
        }
      }
    }
  }
}
</style>
